import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { RUNSETS } from "../../../../constants/applicationConstants";
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../../constants/applicationConstants";
const BaseTestDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const RunSetDataTable = DataTableHoc(BaseTestDataTable);

/**
 * RunSets main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RunSets component.
 */
const RunSets = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          <RunSetDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RUNSETS}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={RUNSETS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RUNSETS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RUNSETS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            isDefaultSlider={true}
            contentSliderTitle={"Run Set"}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default RunSets;
