import { DataColumns } from '../DataColumns/DataColumns';
import GridFlag from '../../../../Core/Components/GridFlag/GridFlag';
import { DateRangeFilterCell, MultiViewCalenderFilterCell } from 'smart-react';
import {
  TenantFilterCell,
  TestGroupFilterCell,
  BooleanValuesFilterCell,
  GridDateCell,
} from '../../../../Core/Components/Filter/FilterElements';
/**
 * Generates a list of columns for a grid.
 * @param {object} options - The options for the grid.
 * @returns {Array} - The list of grid columns.
 */
export const GridColumnsList = async ({
  editScreen,
  dateRangefilter,
  setDateRangeFilter,
}) => {
  return DataColumns({
    editScreen,
    GridFlag,
    TenantFilterCell,
    TestGroupFilterCell,
    BooleanValuesFilterCell,
    DateRangeFilterCell,
    dateRangeFilter: dateRangefilter,
    setDateRangeFilter,
    GridDateCell,
    MultiViewCalenderFilterCell,
  });
};

export default GridColumnsList;
